import { default as energy_45green_45practicesStXjjt0pqOMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journey4FZwsj3Z1iMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderDGPEaZUAxlMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexaTKYAlI1f5Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamBW7Gznj6LYMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderGuh1sCX7NyMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesDtQslZwq3OMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93swuxJQmi3EMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexr3DhDFpMoeMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexrPjTIbKAYPMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93GVSGLblzT6Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexssNtPWcBtdMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45questionK6W2KIkF9DMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesISHyZajU8SMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faq6IgBJdmT2UMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourceszgTgAejObqMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexkE7TFUb5MsMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsruLewPv4HiMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersAlVUrutYsAMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexzHbDspEPesMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexfMyk60SJb2Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexMo3ulnqc2cMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index1HIfPSaCWiMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexbqTQxIvBT4Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexa7rrNQhBgzMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexXKTaIx4LlAMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationZVyCTg0PeVMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionGXx095e7hRMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeWkadPPI6P5Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceKSNEkGbY2EMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_939dARQEF6C7Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexilMrZIn4eXMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexPnSRwwXzcCMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullness0bBEt8Xjk5Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93TmbJ9ickzkMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favorites6tSeaWWFC7Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchR2f8mNaMIeMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouseSvE7R6I8KVMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededition8hgH01BQ3pMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageKk78bZaj4fMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as index6ngVLs6LLpMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsFRqkvoKX2fMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usPVsjYuHhcWMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacyterms1JcauLLp4IMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxB88LmKY2jBMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93ZCgXEGkjV7Meta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubTaecrCWoisMeta } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubTaecrCWois } from "/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesStXjjt0pqOMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesStXjjt0pqOMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesStXjjt0pqOMeta || {},
    alias: energy_45green_45practicesStXjjt0pqOMeta?.alias || [],
    redirect: energy_45green_45practicesStXjjt0pqOMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journey4FZwsj3Z1iMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journey4FZwsj3Z1iMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journey4FZwsj3Z1iMeta || {},
    alias: fun_45part_45journey4FZwsj3Z1iMeta?.alias || [],
    redirect: fun_45part_45journey4FZwsj3Z1iMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderDGPEaZUAxlMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderDGPEaZUAxlMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderDGPEaZUAxlMeta || {},
    alias: homesafe_45colorado_45master_45builderDGPEaZUAxlMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderDGPEaZUAxlMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexaTKYAlI1f5Meta?.name ?? "about-us",
    path: indexaTKYAlI1f5Meta?.path ?? "/about-us",
    meta: indexaTKYAlI1f5Meta || {},
    alias: indexaTKYAlI1f5Meta?.alias || [],
    redirect: indexaTKYAlI1f5Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamBW7Gznj6LYMeta?.name ?? "about-us-our-team",
    path: our_45teamBW7Gznj6LYMeta?.path ?? "/about-us/our-team",
    meta: our_45teamBW7Gznj6LYMeta || {},
    alias: our_45teamBW7Gznj6LYMeta?.alias || [],
    redirect: our_45teamBW7Gznj6LYMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderGuh1sCX7NyMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderGuh1sCX7NyMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderGuh1sCX7NyMeta || {},
    alias: preferred_45lenderGuh1sCX7NyMeta?.alias || [],
    redirect: preferred_45lenderGuh1sCX7NyMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesDtQslZwq3OMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesDtQslZwq3OMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesDtQslZwq3OMeta || {},
    alias: realtor_45resourcesDtQslZwq3OMeta?.alias || [],
    redirect: realtor_45resourcesDtQslZwq3OMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93swuxJQmi3EMeta?.name ?? "blog-post",
    path: _91post_93swuxJQmi3EMeta?.path ?? "/blog/:post()",
    meta: _91post_93swuxJQmi3EMeta || {},
    alias: _91post_93swuxJQmi3EMeta?.alias || [],
    redirect: _91post_93swuxJQmi3EMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexr3DhDFpMoeMeta?.name ?? "blog-category-category",
    path: indexr3DhDFpMoeMeta?.path ?? "/blog/category/:category()",
    meta: indexr3DhDFpMoeMeta || {},
    alias: indexr3DhDFpMoeMeta?.alias || [],
    redirect: indexr3DhDFpMoeMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrPjTIbKAYPMeta?.name ?? "blog",
    path: indexrPjTIbKAYPMeta?.path ?? "/blog",
    meta: indexrPjTIbKAYPMeta || {},
    alias: indexrPjTIbKAYPMeta?.alias || [],
    redirect: indexrPjTIbKAYPMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93GVSGLblzT6Meta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93GVSGLblzT6Meta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93GVSGLblzT6Meta || {},
    alias: _91_46_46_46slug_93GVSGLblzT6Meta?.alias || [],
    redirect: _91_46_46_46slug_93GVSGLblzT6Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexssNtPWcBtdMeta?.name ?? "campaigns",
    path: indexssNtPWcBtdMeta?.path ?? "/campaigns",
    meta: indexssNtPWcBtdMeta || {},
    alias: indexssNtPWcBtdMeta?.alias || [],
    redirect: indexssNtPWcBtdMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionK6W2KIkF9DMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionK6W2KIkF9DMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionK6W2KIkF9DMeta || {},
    alias: ask_45a_45questionK6W2KIkF9DMeta?.alias || [],
    redirect: ask_45a_45questionK6W2KIkF9DMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesISHyZajU8SMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesISHyZajU8SMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesISHyZajU8SMeta || {},
    alias: career_45opportunitiesISHyZajU8SMeta?.alias || [],
    redirect: career_45opportunitiesISHyZajU8SMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faq6IgBJdmT2UMeta?.name ?? "contact-us-faq",
    path: faq6IgBJdmT2UMeta?.path ?? "/contact-us/faq",
    meta: faq6IgBJdmT2UMeta || {},
    alias: faq6IgBJdmT2UMeta?.alias || [],
    redirect: faq6IgBJdmT2UMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourceszgTgAejObqMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourceszgTgAejObqMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourceszgTgAejObqMeta || {},
    alias: homeowner_45resourceszgTgAejObqMeta?.alias || [],
    redirect: homeowner_45resourceszgTgAejObqMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexkE7TFUb5MsMeta?.name ?? "contact-us",
    path: indexkE7TFUb5MsMeta?.path ?? "/contact-us",
    meta: indexkE7TFUb5MsMeta || {},
    alias: indexkE7TFUb5MsMeta?.alias || [],
    redirect: indexkE7TFUb5MsMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsruLewPv4HiMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsruLewPv4HiMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsruLewPv4HiMeta || {},
    alias: our_45locationsruLewPv4HiMeta?.alias || [],
    redirect: our_45locationsruLewPv4HiMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersAlVUrutYsAMeta?.name ?? "current-offers",
    path: current_45offersAlVUrutYsAMeta?.path ?? "/current-offers",
    meta: current_45offersAlVUrutYsAMeta || {},
    alias: current_45offersAlVUrutYsAMeta?.alias || [],
    redirect: current_45offersAlVUrutYsAMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexzHbDspEPesMeta?.name ?? "events-event",
    path: indexzHbDspEPesMeta?.path ?? "/events/:event()",
    meta: indexzHbDspEPesMeta || {},
    alias: indexzHbDspEPesMeta?.alias || [],
    redirect: indexzHbDspEPesMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfMyk60SJb2Meta?.name ?? "events",
    path: indexfMyk60SJb2Meta?.path ?? "/events",
    meta: indexfMyk60SJb2Meta || {},
    alias: indexfMyk60SJb2Meta?.alias || [],
    redirect: indexfMyk60SJb2Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexMo3ulnqc2cMeta?.name ?? "homes-location-community",
    path: indexMo3ulnqc2cMeta?.path ?? "/homes/:location()/:community()",
    meta: indexMo3ulnqc2cMeta || {},
    alias: indexMo3ulnqc2cMeta?.alias || [],
    redirect: indexMo3ulnqc2cMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: index1HIfPSaCWiMeta?.name ?? "homes-location-community-models-model",
    path: index1HIfPSaCWiMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: index1HIfPSaCWiMeta || {},
    alias: index1HIfPSaCWiMeta?.alias || [],
    redirect: index1HIfPSaCWiMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbqTQxIvBT4Meta?.name ?? "homes-location-community-plans-plan",
    path: indexbqTQxIvBT4Meta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexbqTQxIvBT4Meta || {},
    alias: indexbqTQxIvBT4Meta?.alias || [],
    redirect: indexbqTQxIvBT4Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa7rrNQhBgzMeta?.name ?? "homes-location-community-qmi-home",
    path: indexa7rrNQhBgzMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexa7rrNQhBgzMeta || {},
    alias: indexa7rrNQhBgzMeta?.alias || [],
    redirect: indexa7rrNQhBgzMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXKTaIx4LlAMeta?.name ?? "index",
    path: indexXKTaIx4LlAMeta?.path ?? "/",
    meta: indexXKTaIx4LlAMeta || {},
    alias: indexXKTaIx4LlAMeta?.alias || [],
    redirect: indexXKTaIx4LlAMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationZVyCTg0PeVMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationZVyCTg0PeVMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationZVyCTg0PeVMeta || {},
    alias: knolls_45hoa_45informationZVyCTg0PeVMeta?.alias || [],
    redirect: knolls_45hoa_45informationZVyCTg0PeVMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionGXx095e7hRMeta?.name ?? "land-acquisition",
    path: land_45acquisitionGXx095e7hRMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionGXx095e7hRMeta || {},
    alias: land_45acquisitionGXx095e7hRMeta?.alias || [],
    redirect: land_45acquisitionGXx095e7hRMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeWkadPPI6P5Meta?.name ?? "lifestyle-brents-place",
    path: brents_45placeWkadPPI6P5Meta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeWkadPPI6P5Meta || {},
    alias: brents_45placeWkadPPI6P5Meta?.alias || [],
    redirect: brents_45placeWkadPPI6P5Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceKSNEkGbY2EMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceKSNEkGbY2EMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceKSNEkGbY2EMeta || {},
    alias: experience_45lower_45maintenanceKSNEkGbY2EMeta?.alias || [],
    redirect: experience_45lower_45maintenanceKSNEkGbY2EMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_939dARQEF6C7Meta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_939dARQEF6C7Meta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_939dARQEF6C7Meta || {},
    alias: _91testimonial_939dARQEF6C7Meta?.alias || [],
    redirect: _91testimonial_939dARQEF6C7Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexilMrZIn4eXMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexilMrZIn4eXMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexilMrZIn4eXMeta || {},
    alias: indexilMrZIn4eXMeta?.alias || [],
    redirect: indexilMrZIn4eXMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPnSRwwXzcCMeta?.name ?? "lifestyle-reviews",
    path: indexPnSRwwXzcCMeta?.path ?? "/lifestyle/reviews",
    meta: indexPnSRwwXzcCMeta || {},
    alias: indexPnSRwwXzcCMeta?.alias || [],
    redirect: indexPnSRwwXzcCMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullness0bBEt8Xjk5Meta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullness0bBEt8Xjk5Meta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullness0bBEt8Xjk5Meta || {},
    alias: whats_45lifefullness0bBEt8Xjk5Meta?.alias || [],
    redirect: whats_45lifefullness0bBEt8Xjk5Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TmbJ9ickzkMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93TmbJ9ickzkMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93TmbJ9ickzkMeta || {},
    alias: _91_46_46_46slug_93TmbJ9ickzkMeta?.alias || [],
    redirect: _91_46_46_46slug_93TmbJ9ickzkMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favorites6tSeaWWFC7Meta?.name ?? "my-favorites",
    path: my_45favorites6tSeaWWFC7Meta?.path ?? "/my-favorites",
    meta: my_45favorites6tSeaWWFC7Meta || {},
    alias: my_45favorites6tSeaWWFC7Meta?.alias || [],
    redirect: my_45favorites6tSeaWWFC7Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchR2f8mNaMIeMeta?.name ?? "new-home-search",
    path: new_45home_45searchR2f8mNaMIeMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchR2f8mNaMIeMeta || {},
    alias: new_45home_45searchR2f8mNaMIeMeta?.alias || [],
    redirect: new_45home_45searchR2f8mNaMIeMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouseSvE7R6I8KVMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouseSvE7R6I8KVMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouseSvE7R6I8KVMeta || {},
    alias: easyhouseSvE7R6I8KVMeta?.alias || [],
    redirect: easyhouseSvE7R6I8KVMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededition8hgH01BQ3pMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededition8hgH01BQ3pMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededition8hgH01BQ3pMeta || {},
    alias: limitededition8hgH01BQ3pMeta?.alias || [],
    redirect: limitededition8hgH01BQ3pMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageKk78bZaj4fMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageKk78bZaj4fMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageKk78bZaj4fMeta || {},
    alias: wee_45cottageKk78bZaj4fMeta?.alias || [],
    redirect: wee_45cottageKk78bZaj4fMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: index6ngVLs6LLpMeta?.name ?? "our-homes",
    path: index6ngVLs6LLpMeta?.path ?? "/our-homes",
    meta: index6ngVLs6LLpMeta || {},
    alias: index6ngVLs6LLpMeta?.alias || [],
    redirect: index6ngVLs6LLpMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsFRqkvoKX2fMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsFRqkvoKX2fMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsFRqkvoKX2fMeta || {},
    alias: our_45neighborhoodsFRqkvoKX2fMeta?.alias || [],
    redirect: our_45neighborhoodsFRqkvoKX2fMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usPVsjYuHhcWMeta?.name ?? "partner-with-us",
    path: partner_45with_45usPVsjYuHhcWMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usPVsjYuHhcWMeta || {},
    alias: partner_45with_45usPVsjYuHhcWMeta?.alias || [],
    redirect: partner_45with_45usPVsjYuHhcWMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacyterms1JcauLLp4IMeta?.name ?? "privacyterms",
    path: privacyterms1JcauLLp4IMeta?.path ?? "/privacyterms",
    meta: privacyterms1JcauLLp4IMeta || {},
    alias: privacyterms1JcauLLp4IMeta?.alias || [],
    redirect: privacyterms1JcauLLp4IMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxB88LmKY2jBMeta?.name ?? "sandbox",
    path: sandboxB88LmKY2jBMeta?.path ?? "/sandbox",
    meta: sandboxB88LmKY2jBMeta || {},
    alias: sandboxB88LmKY2jBMeta?.alias || [],
    redirect: sandboxB88LmKY2jBMeta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ZCgXEGkjV7Meta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93ZCgXEGkjV7Meta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93ZCgXEGkjV7Meta || {},
    alias: _91_46_46_46slug_93ZCgXEGkjV7Meta?.alias || [],
    redirect: _91_46_46_46slug_93ZCgXEGkjV7Meta?.redirect,
    component: () => import("/codebuild/output/src3146484405/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/build-with-us/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/career-opportunities/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/email-signup/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/get-updates/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/lifestyle/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/login/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/lost-password/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/maintenance-items/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/organism-page/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/register/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/reset-password/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/style-guide/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  },
  {
    name: component_45stubTaecrCWoisMeta?.name ?? undefined,
    path: component_45stubTaecrCWoisMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubTaecrCWoisMeta || {},
    alias: component_45stubTaecrCWoisMeta?.alias || [],
    redirect: component_45stubTaecrCWoisMeta?.redirect,
    component: component_45stubTaecrCWois
  }
]